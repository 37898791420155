import React from "react"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const PaginationLinks = ({ currentPage, numberOfPages, pageUrl }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  
  const previousPage =
    currentPage - 1 === 1 ? pageUrl + "/" : pageUrl + (currentPage - 1).toString()
  const nextPage = pageUrl + "/" + (currentPage + 1).toString()
  return (
    <div className="justify">
      <Pagination aria-label="Page navigation example">
        {isFirst ? (
          <PaginationItem disabled>
            <PaginationLink previous href={pageUrl} />
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink previous href={previousPage} />
          </PaginationItem>
        )}
        {Array.from({ length: numberOfPages }, (_, i) =>
          currentPage === i + 1 ? (
            <PaginationItem active key={`page-number ${i + 1}`}>
              <PaginationLink
                href={pageUrl + `${i === 0 ? "/" : "/" + (i + 1)}`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ) : (
            <PaginationItem key={`page-number ${i + 1}`}>
              <PaginationLink
                href={pageUrl + `${i === 0 ? "/" : "/" + (i + 1)}`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          )
        )}
        {isLast ? (
          <PaginationItem disabled>
            <PaginationLink next href={nextPage} />
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink next href={nextPage} />
          </PaginationItem>
        )}
      </Pagination>
    </div>
  )
}
export default PaginationLinks
