import React from "react"
import Layout from "../components/layout"
import Post from "../components/post"
import PaginationLinks from '../components/pagination'
import { graphql } from "gatsby"

const PostList = props => {
  const posts = props.data.allMarkdownRemark.edges
  const { currentPage, numberOfBlogPages, pageUrl } = props.pageContext

  return (
    <Layout pageTitle={`Home`}>
      {posts.map(({ node }) => {
        return (
          <Post
            key={node.id}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            author={node.frontmatter.author}
            slug={node.fields.slug}
            body={node.excerpt}
            fluid={node.frontmatter.image.childImageSharp.fluid}
            tags={node.frontmatter.tags}
          />
        )
      })}
      <PaginationLinks pageUrl={pageUrl} currentPage={currentPage} numberOfPages = {numberOfBlogPages}/>
    </Layout>
  )
}

export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            
            date(formatString: "MMM Do YYYY")
            author

            tags
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`

export default PostList
